import {Tokens} from "@/plugins/shield/models/token";
import {User} from "@/plugins/shield/models/user";
import utils from "@/plugins/shield/utils/utils";
import store from '@/store';

export class Context {
  private contextTokens: Tokens

  constructor(tokens: any) {
    this.contextTokens = tokens
      store.commit('setUser', this.getUser())
  }

  getExpiresIn():number {
    return this.contextTokens.expires_in
  }

  getContext(): Tokens {
    return this.contextTokens
  }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    getUser(): User {
    const parsedToken = utils.parseJWT(this.contextTokens.token)
        return {
            username: parsedToken.preferred_username,
            first_name: parsedToken.given_name,
            last_name: parsedToken.family_name,
            picture: parsedToken.picture,
            email: parsedToken.email,
            tenant: parsedToken.tenant,
            phone: parsedToken.phone_number,
            access_token: this.contextTokens.token,
            refresh_token: this.contextTokens.refresh_token,
            id_token: this.contextTokens.id_token,
            street_address: parsedToken.address.street_address,
            locality: parsedToken.address.locality,
            region: parsedToken.address.region,
            postal_code: parsedToken.address.postal_code,
            roles: parsedToken.realm_access,
            resource_access: parsedToken.resource_access,
            realm_access: parsedToken.realm_access,
            groups: parsedToken.groups
        }
  }

}


