
import Vue from "vue";
import appCard from "../card/app-card.vue";
import { openmairieWebsiteUrl } from "@/app.config";
import {mapGetters} from "vuex";
export default Vue.extend({
  components: { appCard },
  name: "appMain",
  data() {
    return {
      cards:[
        {
          id: "12",
          link: "/schedule-app",
          externalLink: false,
          title: "douze",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/schedule-manager.svg",
          labelName: "Gestion des rendez-vous",
          group: 'schedule-manager',
          alwaysVisible: false
        },
        {
          id: "13",
          link: "/content-app",
          externalLink: false,
          title: "treize",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/content-manager.svg",
          labelName: "Gestion de contenus",
          group: 'content-manager',
          alwaysVisible: false
        },
        {
          id: "14",
          link: 'https://openmairie.qa.nathos.dev',
          externalLink: true,
          title: "quatorze",
          iconN: "https://openmairie-static-cdn.azureedge.net/cdn/site.svg",
          labelName: "Site Habitant de la commune",
          alwaysVisible: true
        },
        {
          id: "15",
          link: '/hospi-app',
          externalLink: false,
          title: "quinze",
          iconN: "https://openmairie.blob.core.windows.net/cdn/hospital-building (1).png",
          labelName: "Hospi Gestion",
          group: 'hospi-manager',
          alwaysVisible: false
        },
        {
          id: "16",
          link: '/service-app',
          externalLink: false,
          title: "seize",
          iconN: "https://openmairie.blob.core.windows.net/cdn/planning.png",
          labelName: "Gestionnaire de services",
          group: 'service-manager',
          alwaysVisible: false
        },
        {
          id: '17',
          link:  'https://gest-flotte-qa.nathos.dev',
          externalLink: true,
          title: 'dix-sept',
          iconN: 'https://openmairie.blob.core.windows.net/cdn/checklist.png',
          labelName: 'Gestflotte',
          group: 'gest-flotte',
          alwaysVisible: false,
        }
      ],
    };
  },
  methods: {
    filteredCards() {
      const roles =
          this.getUser && this.getUser.realm_access
              ? this.getUser.realm_access.roles
              : [];
      return this.cards.filter((card) => {
        return card.alwaysVisible || (card.group && roles.includes(card.group));
      });
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
  }
});
